<template>
  <div>
    <b-tabs>
      <b-tab title="English">
        <b-card>
      <b-card-title class="d-flex justify-content-between">
        <div>
          Room Details for
          <b-badge v-if="inventory.RoomName" v-once variant="primary">{{
            inventory.RoomName
          }}</b-badge>
        </div>
        <div>
          <b-button
            :to="`/hotel/${hotelID}/inventory-data/${RoomId}/rate-plans`"
            variant="primary"
            >View Rate Plans</b-button
          >
        </div>
      </b-card-title>
          <validation-observer ref="inventoryBasicValidation">
        <b-form>
          <b-row>
            <b-col cols="8">
              <b-form-group label="Room Name">
                <validation-provider
                  #default="{ errors }"
                  name="Room Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="inventory.RoomName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Room Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Description Title">
                <b-form-input
                  v-model="inventory.descriptionTitle"
                  placeholder="Description Title"
                />
              </b-form-group>
              <b-form-group label="Room Description">
                <b-form-textarea
                  placeholder="Room Description"
                  v-model="inventory.description"
                  rows="3"
                />
              </b-form-group>
              <b-row>
                <b-col class="align-self-center">
                  <b-form-checkbox
                    checked="true"
                    v-model="inventory.room"
                    name="check-button"
                    switch
                    inline
                  >
                    {{ inventory.room ? "Room" : "Not Room" }}
                  </b-form-checkbox>
                </b-col>
                <b-col v-if="!inventory.room">
                  <b-form-group label="Max Occupancy">
                    <validation-provider
                      #default="{ errors }"
                      name="Max Occupancy"
                      rules="required"
                    >
                      <b-form-input
                        v-model="inventory.maxOccupancy"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Max Occupancy"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-else>
                  <b-form-group label="Room Occupancy ">
                    <validation-provider
                      #default="{ errors }"
                      name="Room Occupancy"
                      rules="required"
                    >
                      <b-form-input
                        v-model="inventory.maxOccupancyRoom"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Room Occupancy"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group  label="Guest Occupancy">
                        <validation-provider
                      #default="{ errors }"
                      name="Guest Occupancy"
                      rules="required"
                    >
                      <b-form-input
                        v-model="inventory.maxGuest"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Guest Occupancy"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6" class="align-self-center">
              <b-form-group label="Thumbnail Image">
                <ComponentMediaSelector
                  :isMultiple="false"
                  @mediaSelected="handleThumbnailSelect"
                />
              </b-form-group>
              <b-img
                v-if="inventory.thumbnailImg"
                v-bind="mainProps"
                :src="inventory.thumbnailImg"
              ></b-img>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card title="Amenities">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Other Amenities">
            <v-select
              multiple
              :reduce="
                (amenity) => {
                  return { amenity: amenity._id };
                }
              "
              v-model="inventory.amenities"
              label="name"
              placeholder="Other Amenities"
              :options="amenities"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mt-1">
          <b-card-title>Main Amenities</b-card-title>
          <b-form
            ref="mainAmenityForm"
            class="repeater-form"
            @submit.prevent="repeatMainAmenity(null)"
          >
            <b-row
              v-for="(amenity, index) in inventory.mainAmenities"
              :id="index"
              :key="amenity.id"
              ref="amenityrow"
            >
              <b-col md="7">
                <b-form-group label="Food" label-for="food-name">
                  <b-form-input
                    v-model="inventory.mainAmenities[index].content"
                    type="text"
                    placeholder="Content"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center text-center">
                 <a
                  @click="inventory.mainAmenities[index].icon =''"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
                <b-img
                  class="mb-1"
                  v-bind="amenityProps"
                  v-if="inventory.mainAmenities[index].icon"
                  :src="inventory.mainAmenities[index].icon"
                ></b-img>
               
                <ComponentMediaSelector
                  :params="{lang:null, index }"
                  @mediaSelected="handleAmenityMediaSelect"
                  :isMultiple="false"
                />
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeMainAmenityItem(null,index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatMainAmenity(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    inventory.mainAmenities.length === 0
                      ? "Add Main Amenities"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Gallery">
      <div class="mb-1 gallery" v-if="inventory.gallery.length > 0">
        <div
          class="gallery-item"
          :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
          v-for="(media, index) in inventory.gallery"
          :key="media.key"
        >
        <div class="actions">
                <b-button
                  size="sm"
                  @click="removeInventoryGalleryMedia(null, index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
        </div>
          <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
        </div>
      </div>
      <ComponentMediaSelector
        v-if="!isDataLoading"
        :selectedMediaFiles="inventory.gallery"
        @mediaSelected="handleMediaSelect"
      />
    </b-card>
      </b-tab>

      <b-tab title="Hindi">
        <b-card>
        <b-form>
          <b-row>
            <b-col cols="8">
              <b-form-group label="Room Name">
                  <b-form-input
                    v-model="inventory.translation.hi.RoomName"
                    placeholder="Room Name"
                  />
              </b-form-group>
              <b-form-group label="Description Title">
                <b-form-input
                  v-model="inventory.translation.hi.descriptionTitle"
                  placeholder="Description Title"
                />
              </b-form-group>
              <b-form-group label="Room Description">
                <b-form-textarea
                  placeholder="Room Description"
                  v-model="inventory.translation.hi.description"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
    </b-card>

    <b-card title="Amenities">
      <b-row>
        <b-col cols="12" class="mt-1">
          <b-card-title>Main Amenities</b-card-title>
          <b-form
            ref="mainAmenityForm"
            class="repeater-form"
            @submit.prevent="repeatMainAmenity('hi')"
          >
            <b-row
              v-for="(amenity, index) in inventory.translation.hi.mainAmenities"
              :id="index"
              :key="amenity.id"
              ref="amenityrow"
            >
              <b-col md="7">
                <b-form-group>
                  <b-form-input
                    v-model="inventory.translation.hi.mainAmenities[index].content"
                    type="text"
                    placeholder="Content"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center text-center">
                <a
                v-if="inventory.translation.hi.mainAmenities[index].icon != ''"
                  @click="inventory.translation.hi.mainAmenities[index].icon =''"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
                <b-img
                  class="mb-1"
                  v-bind="amenityProps"
                  v-if="inventory.translation.hi.mainAmenities[index].icon"
                  :src="inventory.translation.hi.mainAmenities[index].icon"
                ></b-img>
                
                <ComponentMediaSelector
                  :params="{ lang: 'hi',index }"
                  @mediaSelected="handleAmenityMediaSelect"
                  :isMultiple="false"
                />
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeMainAmenityItem('hi',index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatMainAmenity('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    inventory.translation.hi.mainAmenities.length === 0
                      ? "Add Main Amenities"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
      </b-tab>

       <b-tab title="Marathi">
        <b-card>
        <b-form>
          <b-row>
            <b-col cols="8">
              <b-form-group label="Room Name">
                  <b-form-input
                    v-model="inventory.translation.mr.RoomName"
                    placeholder="Room Name"
                  />
              </b-form-group>
              <b-form-group label="Description Title">
                <b-form-input
                  v-model="inventory.translation.mr.descriptionTitle"
                  placeholder="Description Title"
                />
              </b-form-group>
              <b-form-group label="Room Description">
                <b-form-textarea
                  placeholder="Room Description"
                  v-model="inventory.translation.mr.description"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
    </b-card>

    <b-card title="Amenities">
      <b-row>
        <b-col cols="12" class="mt-1">
          <b-card-title>Main Amenities</b-card-title>
          <b-form
            ref="mainAmenityForm"
            class="repeater-form"
            @submit.prevent="repeatMainAmenity('mr')"
          >
            <b-row
              v-for="(amenity, index) in inventory.translation.mr.mainAmenities"
              :id="index"
              :key="amenity.id"
              ref="amenityrow"
            >
              <b-col md="7">
                <b-form-group>
                  <b-form-input
                    v-model="inventory.translation.mr.mainAmenities[index].content"
                    type="text"
                    placeholder="Content"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center text-center">
                <a
                v-if="inventory.translation.mr.mainAmenities[index].icon != ''"
                  @click="inventory.translation.mr.mainAmenities[index].icon = ''"
                  class="text-danger d-block"
                  >Remove Thumbnail</a
                >
                <b-img
                  class="mb-1"
                  v-bind="amenityProps"
                  v-if="inventory.translation.mr.mainAmenities[index].icon"
                  :src="inventory.translation.mr.mainAmenities[index].icon"
                ></b-img>
                
                <ComponentMediaSelector
                  :params="{ lang:'mr', index }"
                  @mediaSelected="handleAmenityMediaSelect"
                  :isMultiple="false"
                />
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeMainAmenityItem('mr',index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatMainAmenity('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    inventory.translation.mr.mainAmenities.length === 0
                      ? "Add Main Amenities"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
      </b-tab>

    </b-tabs>

    <b-card>
      <b-button @click="updateInventory" block variant="primary" size="lg"
        >Update Inventory</b-button
      >
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import {
  BForm,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardTitle,
  BButton,
  BRow,
  BTabs,
  BTab,
  BCol,
  BFormTextarea,
  BImg,
  BFormCheckbox,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";

export default {
  components: {
    ComponentMediaSelector,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BAvatar,
    BBadge,
    BTab,
    BTabs,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BCardActions,
    BCardTitle,
    BRow,
    BCol,
    BFormTextarea,
    BImg,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      hotelID: this.$route.params.hotelID,
      RoomId: this.$route.params.roomID,
      isDataLoading: true,
      nextAmenityID: 1,
      mainProps: {
        width: 125,
        height: 125,
      },
      amenityProps: {
        width: 40,
        height: 40,
      },
      amenities: [],
      inventory: {
        RoomName: "",
        thumbnailImg: "",
        description: "",
        descriptionTitle: "",
        mainAmenities: [],
        maxOccupancyRoom: "",
        maxGuest: "",
        amenities: [],
        room: true,
        maxOccupancy: "",
        gallery: [],
        translation: {
          mr: {
            RoomName: "",
        description: "",
        descriptionTitle: "",
        mainAmenities: [],
          },
          hi: {
            RoomName: "",
        description: "",
        descriptionTitle: "",
        mainAmenities: [],
          }
        }
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getInventoryData() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/hotel/inventories/getRoomData`,
          {
            hotelId: this.hotelID,
            RoomId: this.RoomId,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isDataLoading = false;
          console.log(response.data)
          const {
            RoomName,
            mainAmenities,
            amenities,
            gallery,
            descriptionTitle,
            description,
            maxOccupancyRoom,
            maxGuest,
            thumbnailImg,
            room,
            maxOccupancy,
            translation
          } = response.data;
          console.log(response.data);
          this.inventory.RoomName = RoomName;
          this.inventory.mainAmenities = mainAmenities;
          this.inventory.amenities = amenities;
          this.inventory.gallery = gallery;
          this.inventory.thumbnailImg = thumbnailImg ? thumbnailImg : "";
          this.inventory.descriptionTitle = descriptionTitle
            ? descriptionTitle
            : "";

          this.inventory.description = description ? description : "";
          const amenitiesArray = amenities
            ? amenities.map((amenity) => {
                return { amenity: amenity.amenity };
              })
            : [];

          this.inventory.amenities = amenitiesArray;
          this.inventory.room = room;
          this.inventory.maxOccupancy = maxOccupancy ? maxOccupancy : "";
          this.inventory.maxOccupancyRoom = maxOccupancyRoom ? maxOccupancyRoom : "";
          this.inventory.maxGuest = maxGuest ? maxGuest : "";

          this.inventory.translation = {
            hi: {
               RoomName: translation && translation.hi.RoomName ? translation.hi.RoomName : "",
        description: translation && translation.hi.description ? translation.hi.description : "",
        descriptionTitle: translation && translation.hi.descriptionTitle ? translation.hi.descriptionTitle : "",
        mainAmenities: translation && translation.hi.mainAmenities ? translation.hi.mainAmenities : [],
            },
            mr: {
               RoomName: translation && translation.mr.RoomName ? translation.mr.RoomName : "",
        description: translation && translation.mr.description ? translation.mr.description : "",
        descriptionTitle: translation && translation.mr.descriptionTitle ? translation.mr.descriptionTitle : "",
        mainAmenities: translation && translation.mr.mainAmenities ? translation.mr.mainAmenities : [],
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.isDataLoading = false;
          this.$router.push({ name: "error-404" });
        });
    },
    removeInventoryGalleryMedia(lang = undefined, index) {
      if (lang) {
        this.inventory.translation[lang].gallery.splice(index, 1);
      } else {
        this.inventory.gallery.splice(index, 1);
      }
    },
    updateInventory() {
      this.$refs.inventoryBasicValidation.validate().then((success) => {
        if (success) {
          axios
            .post(`${process.env.VUE_APP_SERVER_URL}/hotel/inventories/addRoomData`, {
              hotelId: this.hotelID,
              RoomId: this.RoomId,
              updates: this.inventory
            }, {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          })
            .then((response) => {
              console.log(this)
              console.log(response.data)
              this.makeToast(
                "success",
                "Success",
                "Inventory updated successfully!"
              );
            })
            .catch((error) => {
              this.makeToast(
                "danger",
                "Error",
                "Couldn't update the Inventory"
              );
            });
        }
      });
    },
    handleThumbnailSelect(mediaObject) {
      if (mediaObject.status) {
        this.inventory.thumbnailImg = mediaObject.media.src;
      }
    },
    handleMediaSelect(mediaObject) {
      if (mediaObject.status) {
        this.inventory.gallery = mediaObject.media;
      }
    },
    getHotelAmenities() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/amenity/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.amenities = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Hotel Themes Data");
        });
    },
    handleAmenityMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
          this.inventory.translation[mediaObject.params.lang].mainAmenities[mediaObject.params.index].icon =
          mediaObject.media.src;
        } else {
          this.inventory.mainAmenities[mediaObject.params.index].icon =
          mediaObject.media.src;
        }
        
      }
    },
    removeMainAmenityItem(lang=undefined, index) {
      if(lang) {
      this.inventory.translation[lang].mainAmenities.splice(index, 1);
      } else {
      this.inventory.mainAmenities.splice(index, 1);
      }
    },
    repeatMainAmenity(lang=undefined) {
      if(lang) {
         this.inventory.translation[lang].mainAmenities.push({
        content: "",
        icon: ""
      });
      } else {
      this.inventory.mainAmenities.push({
        content: "",
        icon: ""
      });
      }
    },
  },
  created() {
    this.getInventoryData();
    this.getHotelAmenities();
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.gallery-item {
  position: relative;
}

 .actions {
      position: absolute;
      top: 5px;
      right: 10px;
    }
.gallery {
  display: flex;
}
</style>